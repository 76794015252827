import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const headers = {};

    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('jwt')) {
        headers['Authorization'] = 'Bearer ' + localStorage.getItem('jwt');
      }
    }

    request = request.clone({
      setHeaders: headers
    });

    return next.handle(request)
      .pipe(tap(
        (event: HttpEvent<any>) => {
          // if (event instanceof HttpResponse) { }
        },
        (error: any) => {
          if (error instanceof HttpErrorResponse) {
            // TODO Handle invitation login error
            /*
            if (error.status === 401) {
              localStorage.removeItem('jwt');
              this.router.navigate(['/login']);
            }
            */
          }
        }
      ));
  }
}
