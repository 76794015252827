<nav class="nav-bottom" [class.show]="show">
  <div (click)="toggleNavigation('categories')">
    <span class="ns-icon categories"></span>
    <span class="title">Kategorije</span>
  </div>
  <div>
    <span class="ns-icon search"></span>
    <span class="title">Pretraga</span>
  </div>
  <div class="it-position-relative" (click)="compare()">
    <span class="compare-responsive-counter">{{_compareProductsCount}}</span>
    <span class="ns-icon compare-square"></span>
    <span class="title">Uporedi</span>
  </div>
  <div>
    <span class="ns-icon login"></span>
    <span class="title">Ulogujte se</span>
  </div>
</nav>
