import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private cartSubject = new BehaviorSubject<any[]>(null);
  cart = this.cartSubject.asObservable();

  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  getCart() {
    if (isPlatformBrowser(this.platformId)) {
      const cart = JSON.parse(localStorage.getItem('cart'));
      if(!cart) {
        localStorage.setItem('cart', JSON.stringify({
          promotion_code: null,
          products: []
        }));
      }
      this.cartSubject.next(cart);
      return JSON.parse(localStorage.getItem('cart'));
    } else {
      return null;
    }
  }

  addToCart(productId: string) {
    if (isPlatformBrowser(this.platformId)) {
      const cart = this.getCart();
      const selectedProduct = cart.products.find(element => element.product_id === productId);
      if(!selectedProduct) {
        cart.products.push({
          product_id: productId,
          quantity: 1
        });
        localStorage.setItem('cart', JSON.stringify(cart));
        this.cartSubject.next(cart);
      }
    }
  }

  removeProduct(cartProductId: string) {
    if (isPlatformBrowser(this.platformId)) {
      const cart = this.getCart();
      const selectedProduct = cart.products.find(element => element.product_id === cartProductId);

      if(selectedProduct) {
        cart.products.splice(cart.products.indexOf(selectedProduct), 1);
        localStorage.setItem('cart', JSON.stringify(cart));
        this.cartSubject.next(cart);
      }
    }
  }

  removeCart() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('cart', JSON.stringify({
        promotion_code: null,
        products: []
      }));
      this.cartSubject.next(null);
    }
  }

  quantityChange(type, productId) {
    if (isPlatformBrowser(this.platformId)) {
      const cart = this.getCart();
      const product = cart.products.find(product => product.product_id === productId);
      if(type === 'minus') {
        if(product.quantity == 1) { return; }
        --product.quantity;
      }else {
        ++product.quantity;
      }

      localStorage.setItem('cart', JSON.stringify(cart));
      this.cartSubject.next(cart);
    }
  }

}
