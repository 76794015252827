import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ns-responsive-navigation',
  templateUrl: './responsive-navigation.component.html'
})
export class ResponsiveNavigationComponent implements OnInit {
  @Input() show: boolean = false;
  @Input() set compareProductsCount(val) {
    this._compareProductsCount = val;
  }
  @Output() valueChange = new EventEmitter();
  @Output() compareClick = new EventEmitter();

  _compareProductsCount: number;
  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {}

  toggleNavigation(type: string) {
    this.valueChange.emit(type);
  }

  compare() {
    this.compareClick.emit(true);
  }

}
