import { map, Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { PageService } from '../services/page.service';

@Injectable()
export class PageCategoryResolver implements Resolve<any> {

  constructor(
    private router: Router,
    private pageService: PageService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this.pageService
      .getPageCategory(route.params.slug)
      .pipe(
        map (
          (response) => {
            return response.data;
          }
        )
      );
  }
}


