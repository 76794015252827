import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private apiService: ApiService,
    private userService: UserService,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  login(params: any) {
    if (isPlatformBrowser(this.platformId)) {
      return this.apiService.post(`login`, params)
        .pipe(
          map((response: any) => {
            if (response && response.access_token) {
              localStorage.setItem('jwt', response.access_token);
            }
            return response;
          })
        );
    } else {
      return null;
    }
  }

  register(params: any) {
    if (isPlatformBrowser(this.platformId)) {
      return this.apiService.post(`register`, params)
        .pipe(
          map((response: any) => {
            if (response && response.access_token) {
              localStorage.setItem('jwt', response.access_token);
            }

            return response;
          })
        );
    } else {
      return null;
    }
  }

  logout() {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('jwt')) {
        return this.apiService.post(`logout`)
          .pipe(
            map((response: any) => {
              localStorage.removeItem('jwt');
              this.userService.currentUserSubject.next(null);
            })
          );
      }
    } else {
      return null;
    }
  }

  changePasswordRequest(params = {}) {
    return this.apiService.post(`password_reset_request`, params);
  }

  changePassword(token: string, params = {}) {
    return this.apiService.post(`password_reset/${token}`, params);
  }
}
