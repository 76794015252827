import { map, Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from "@angular/router";
import { GlobalService } from '../services/global.service';

@Injectable()
export class WebsiteResolver implements Resolve<any> {

  constructor(
    private router: Router,
    private globalService: GlobalService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this.globalService.getWebsite('8c63a0c5-6257-44dc-ae38-e2d757e64503', { include: 'navigations.navigation_items.entity,navigations.navigation_items.featured_product' })
    .pipe(
      map(
        (response) => {
          return response.data;
        }
      )
    );
  }

}
