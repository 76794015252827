import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResponsiveNavigationComponent } from './responsive-navigation.component';

@NgModule({
  declarations: [ ResponsiveNavigationComponent ],
  imports: [
    CommonModule
  ],
  exports: [
    ResponsiveNavigationComponent
  ]
})
export class ResponsiveNavigationModule { }
