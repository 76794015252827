<footer class="ns-footer ns-container">
  <div class="it-row center footer-navigation">
    <div class="it-col md-3">
      <ul class="payment">
        <li class="title">Plaćanje putem</li>
        <li class="cards">
          <img src="assets/images/bank/maestro.png" width="45px" alt="" />
          <img src="assets/images/bank/mastercard.png" width="45px" alt="" />
          <img src="assets/images/bank/dina.png" width="45px" alt="" />
          <img src="assets/images/bank/visa.png" width="45px" alt="" />
          <img
            src="assets/images/bank/american-express.png"
            width="45px"
            alt=""
          />
          <br />
          <a href="https://www.bancaintesa.rs" target="_blank">
            <img
              class="mt-12 mb-4"
              src="assets/images/bank/banca-intesa.png"
              width="275px"
              alt=""
            />
          </a>
          <br />
          <a
            href="http://www.mastercard.com/rs/consumer/credit-cards.html"
            target="_blank"
          >
            <img
              src="assets/images/bank/mastercard-idcheck.png"
              width="150px"
              alt=""
            />
          </a>
          <a
            href="https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html"
            target="_blank"
          >
            <img src="assets/images/bank/visa-secure.png" width="60px" alt="" />
          </a>
        </li>
      </ul>
    </div>
    <div class="it-col md-3">
      <ul>
        <li class="title">Radno vreme</li>
        <li class="mb-16">
          Radnim danom od 9-20h, subotom od 9-15h<br />
          Želimo vam prijatnu i udobnu kupovinu u NAVI<br />
          SHOP prodavnici.
        </li>
        <li class="mb-16">Povežite se sa nama na društvenim mrežama.</li>
        <li class="social-media">
          <a
            class="ns-icon facebook"
            href="https://www.facebook.com/www.navishop.rs"
            target="_blank"
          ></a>
          <a
            class="ns-icon instagram"
            href="https://www.instagram.com/shop_navi/"
            target="_blank"
          ></a>
        </li>
      </ul>
    </div>
    <div class="it-col md-3">
      <ul>
        <li class="title">Korisni linkovi</li>
        <li *ngFor="let navItem of footerNavigation">
          <span class="it-clickable" (click)="navigate(navItem)">{{
            navItem.title
          }}</span>
        </li>
      </ul>
    </div>
    <div class="it-col md-3">
      <ul>
        <li class="title">Kontakt</li>
        <li class="contact">
          <span class="ns-icon contact-address"></span>
          <div>
            Bulevar Kralja Aleksandra 193,<br />
            11160 Beograd, Srbija
          </div>
        </li>
        <li class="contact">
          <span class="ns-icon contact-phone"></span>
          <div>
            <a href="tel:+381 (11) 241-2362">+381 (11) 241-2362</a><br />
            <a href="tel:+381 (11) 241-2342">+381 (11) 241-2342</a>
          </div>
        </li>
        <li class="contact">
          <span class="ns-icon contact-email"></span>
          <div>
            <a href="mailto:office@navishop.rs">office&#64;navishop.rs</a>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="it-row">
    <div class="it-col copyright">
      <span>Copyright © Navishop Beograd, Srbija.</span>
      <span>Sva prava zadržana.</span>
    </div>
  </div>
</footer>
