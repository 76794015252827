import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from './navigation.component';
import { ITDropdownModule, ITImageHandlerModule, ITInputModule, ITOffClickModule } from '@infoteam/infoteam-ui';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
@NgModule({
  declarations: [
    NavigationComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ITDropdownModule,
    ITInputModule,
    ITDropdownModule,
    ITOffClickModule,
    ITImageHandlerModule,
    FormsModule
  ],
  exports: [
    NavigationComponent
  ]
})
export class NavigationModule { }
