import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { WebsiteModule } from './website/website.module';
import { WebsiteResolver } from './resolvers/website.resolver';
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    WebsiteModule
  ],
  providers: [
    WebsiteResolver
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
