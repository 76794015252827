<div class="website-root">
  <header>
    <ns-navigation
      [mainNavigation]="mainNavigation"
      [type]="navigationType"
      [showNotification]="cart && cart.products.length > 0"
      (valueChange)="closeNavigation($event)"></ns-navigation>
  </header>
  <main (scroll)="onScroll($event)" itInfiniteScroll (scrolled)="scrolledToBottom('bottom')" [type]="'website'">
    <router-outlet></router-outlet>
    <ns-footer *ngIf="showFooter" [footerNavigation]="footerNavigation"></ns-footer>
    <ns-responsive-navigation [show]="showResponsiveNav" (valueChange)="change($event)" (compareClick)="toggleCompareBubble()" [compareProductsCount]="compareProducts.length"></ns-responsive-navigation>
  </main>

  <it-button class="compare" *ngIf="compareProducts.length > 0 && !showCompareBubble" (click)="toggleCompareBubble()">Uporedi proizvode ({{compareProducts.length}})</it-button>
  <div class="compare-bubble" [class.show]="showCompareBubble">
    <div class="header">
      <h4>Poređenje proizvoda prema kategoriji</h4>
    </div>
    <div class="body">
      <div class="product-category__wrapper" *ngFor="let type of compareProductsShow">
        <div class="product-category">
          <h4>{{type.name}}</h4>
          <div class="product" *ngFor="let product of type.products">
            <div class="content">
              <img
              itImageHandler
              [apiRoot]="fileRoot"
              [imagePath]="product.image"
              imageSize='small'>
            <span>{{product.name}}</span>
            </div>
            <div class="ns-icon menu-close" (click)="deleteCompareProduct(product.id)"></div>
        </div>
        <it-button (click)="compare(type.product_type_id)">Uporedi</it-button>
        </div>
      </div>
    </div>

    <div class="footer">
      <p (click)="toggleCompareBubble()">Zatvori</p>
    </div>

    <div
      class="scroll-to-top" 
      [class.show]="showResponsiveNav"
      (click)="scrollToTop()"
    >
      <span class="it-icon arrow-up"></span>
    </div>

  </div>
</div>
