import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { User } from '../classes/user';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public currentUserSubject = new BehaviorSubject<User>(null as User);
  currentUser = this.currentUserSubject.asObservable();

  constructor(
    private apiService: ApiService
  ) { }

  getUsers(params?: any) {
    return this.apiService.get(`users`, params);
  }

  getUser(userId: string, params?: any) {
    return this.apiService.get(`users/${userId}`, params);
  }

  createUser(params?: any) {
    return this.apiService.post(`users`, params);
  }

  updateUser(userId: string, params?: any) {
    return this.apiService.put(`users/${userId}`, params);
  }

  deleteUser(userId: string) {
    return this.apiService.delete(`users/${userId}`);
  }

  getCurrentUser() {
    return this.apiService.get('users/current')
      .pipe(
        map(
          (response) => {
            this.currentUserSubject.next(response.data);
            return response.data;
          }
        )
      );
  }

  //Adresses

  getAddresses(userId: string) {
    return this.apiService.get(`users/${userId}/addresses`);
  }

  getAddress(userId: string, addressId: any) {
    return this.apiService.get(`users/${userId}/addresses/${addressId}`);
  }

  createAddress(userId: string, params?: any) {
    return this.apiService.post(`users/${userId}/addresses`, params);
  }

  updateAddress(userId: string, addressId: any, params?: any) {
    return this.apiService.put(`users/${userId}/addresses/${addressId}`, params);
  }

  deleteAddress(userId: string, addressId: any) {
    return this.apiService.delete(`users/${userId}/addresses/${addressId}`);
  }

  //Orders

  getOrders(params?: any) {
    return this.apiService.get(`orders`, params);
  }

  getOrder(orderId: string, params?: any) {
    return this.apiService.get(`orders/${orderId}`, params);
  }

  updateOrder(orderId: string, params?: any) {
    return this.apiService.put(`orders/${orderId}`, params);
  }

}
