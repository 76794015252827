import { Routes } from "@angular/router";
import { WebsiteComponent } from './website.component';
import { WebsiteResolver } from './../resolvers/website.resolver';
import { ProductCategoryResolver } from "../resolvers/product-category.resolver";
import { UserResolver } from "../resolvers/user.resolver";
import { ProductTypeResolver } from "../resolvers/product-type.resolver";
import { PageCategoryResolver } from "../resolvers/page-category.resolver";
import { ProductSearchResolver } from "../resolvers/product-search.resolver";

export const WebsiteRoutes: Routes = [
  {
    path: '',
    component: WebsiteComponent,
    resolve: { website: WebsiteResolver, UserResolver },
    children: [
      {
        path: 'page/:slug',
        loadChildren: () => import('./page-details/page-details.module').then((m) => m.PageDetailsModule)
      },
      {
        path: 'page/category/:slug',
        resolve: { page: PageCategoryResolver },
        loadChildren: () => import('./page-list/page-list.module').then((m) => m.PageListModule)
      },
      {
        path: 'product/category/:slug',
        resolve: { data: ProductCategoryResolver },
        loadChildren: () => import('./product-list/product-list.module').then((m) => m.ProductListModule)
      },
      {
        path: 'product/type/:slug',
        resolve: { data: ProductTypeResolver },
        loadChildren: () => import('./product-list/product-list.module').then((m) => m.ProductListModule)
      },
      {
        path: 'product/search',
        resolve: { data: ProductSearchResolver },
        loadChildren: () => import('./product-list/product-list.module').then((m) => m.ProductListModule)
      },
      {
        path: 'product/:slug',
        loadChildren: () => import('./product-details/product-details.module').then((m) => m.ProductDetailsModule)
      },
      {
        path: 'product-compare',
        loadChildren: () => import('./product-compare/product-compare.module').then((m) => m.ProductCompareModule)
      },
      {
        path: 'profile',
        resolve: { user: UserResolver },
        children: [
          {
            path: '',
            loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
          },
          {
            path: 'addresses',
            loadChildren: () => import('./addresses/addresses.module').then((m) => m.AddressesModule),
          },
          {
            path: 'cart',
            loadChildren: () => import('./cart/cart.module').then((m) => m.CartModule)
          },
          {
            path: 'orders',
            loadChildren: () => import('./order-list/order-list.module').then((m) => m.OrderListModule)
          },
          {
            path: 'order/:orderId',
            loadChildren: () => import('./order-details/order-details.module').then((m) => m.OrderDetailsModule)
          },
        ]
      },
      {
        path: 'login',
        loadChildren: () => import('./login/login.module').then((m) => m.LoginModule)
      },
      {
        path: 'registration',
        loadChildren: () => import('./registration/registration.module').then((m) => m.RegistrationModule)
      },
      {
        path: 'registration/:token',
        loadChildren: () => import('./registration/registration.module').then((m) => m.RegistrationModule)
      },
      {
        path: 'password-change',
        loadChildren: () => import('./password-change/password-change.module').then((m) => m.PasswordChangeModule)
      },
      {
        path: 'password-change-request',
        loadChildren: () => import('./password-change-request/password-change-request.module').then((m) => m.PasswordChangeRequestModule)
      },
    ]
  },
]
