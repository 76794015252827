import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Component, OnInit, OnDestroy, PLATFORM_ID, Inject } from '@angular/core';
import { UserService } from '../services/user.service';
import { User } from '../classes/user';
import { Subscription } from 'rxjs';
import { ScrollService } from '../services/scroll.service';
import { CompareService } from '../services/compare.service';
import { environment } from '../../environments/environment';
import { CartService } from '../services/cart.service';
import { ModalService } from 'libs/infoteam-ui/src/lib/components/modal/modal.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'ns-website',
  templateUrl: './website.component.html'
})
export class WebsiteComponent implements OnInit, OnDestroy {
  user: User;
  navigationType: any;

  navigations: any[] = [];
  mainNavigation: any[] = [];
  footerNavigation: any[] = [];
  additionalNavigation: any[] = [];

  compareProducts: any[] = [];
  compareProductsShow: any[] = [];

  cart: any;

  fileRoot: string = environment.fileRoot;

  showFooter: boolean = true;
  showCompareBubble: boolean = false;
  showResponsiveNav: boolean = false;
  subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private scrollService: ScrollService,
    private compareService: CompareService,
    private cartService: CartService,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    const website = this.route.snapshot.data.website;

    // Find landing page if land on root
    if(!route.children.length) {
      let landingPage; 
      
      website.navigations.forEach(navigation => {
        const page = navigation.navigation_items.find(ni => ni.landing_page);
        if (page) { landingPage = page }
      });

      if (landingPage) {
        this.router.navigate([landingPage.type, landingPage.slug]);
      }
    }
  }

  ngOnInit() {
    const userSubscription = this.userService.currentUser
      .subscribe({
        next: (response: User) => {
          this.user = response;
        },
        error: () => { }
      });
    this.subscriptions.push(userSubscription);

    const compareProductsSubscription = this.compareService.compareProducts
      .subscribe({
        next: (response) => {
          if (!response) {
            this.compareProducts = this.compareService.getCompareProducts();
            if (!this.compareProducts) {
              this.compareProducts = [];
            }
          } else {
            this.compareProducts = response;
          }
          if (!this.compareProducts || this.compareProducts.length === 0) { this.showCompareBubble = false; }
          this.compareProductsConfiguration();
        },
        error: () => { }
      })
    this.subscriptions.push(compareProductsSubscription);

    const routerSubscription = this.router.events
      .subscribe(
        (routerEvent: any) => {
          if (routerEvent instanceof NavigationEnd) {
            // console.log(routerEvent.url);

            if (
              routerEvent.url.includes('/product/category') ||
              routerEvent.url.includes('/product/type') ||
              routerEvent.url.includes('/product/search')
            ) {
              this.showFooter = false;
            } else {
              this.showFooter = true;
            }
          }
        }
      );
    this.subscriptions.push(routerSubscription);

    const cartSubscription = this.cartService.cart
      .subscribe({
        next: (response) => {
          this.cart = response;
        },
        error: () => { }
      });
    this.subscriptions.push(cartSubscription);

    this.navigations = this.route.snapshot.data.website.navigations;
    this.getMainNavigation();
    this.getFooterNavigation();
    this.getCart();
  }

  ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  getMainNavigation() {
    let navigationItems = [];
    this.navigations.find(navigation => {
      if (navigation.slug === 'main-navigation-navishop') {
        navigationItems = navigation.navigation_items;
        navigationItems.sort((a, b) => { return a.order - b.order; });
      }
    })
    this.mainNavigation = navigationItems.filter(navItem => !navItem.parent_id);
    if (this.mainNavigation) {
      this.mainNavigation.map(mainNavItem => {
        mainNavItem.subitems = navigationItems.filter(navItem => mainNavItem.id === navItem.parent_id);
        if (mainNavItem.subitems) {
          mainNavItem.subitems.map(subitem => {
            subitem.subsubitems = navigationItems.filter(navItem => subitem.id === navItem.parent_id);
          })
        }
      });
    }
  }

  getFooterNavigation() {
    this.navigations.find(navigation => {
      if (navigation.slug === 'footer-navigation-navishop') {
        this.footerNavigation = navigation.navigation_items;
        this.footerNavigation.sort((a, b) => { return a.order - b.order; });
      }
    })
  }

  onScroll(event) {
    if (event.target.scrollTop >= 300) {
      this.showResponsiveNav = true;
    } else {
      this.showResponsiveNav = false
    }
  }

  closeNavigation(event) {
    if (event === 'close') {
      this.navigationType = null;
    }
  }

  change(event) {
    this.navigationType = event;
  }

  scrolledToBottom(event) {
    this.scrollService.getScrollOffset(event);
  }

  //Compare products

  toggleCompareBubble() {
    this.showCompareBubble = !this.showCompareBubble;
  }

  compareProductsConfiguration() {
    this.compareProductsShow = [];

    this.compareProducts.forEach(element => {
      if (!this.compareProductsShow.find(p => p.product_type_id === element.product_type_id)) {
        this.compareProductsShow.push({
          product_type_id: element.product_type_id,
          name: element.type.title,
          products: [{ id: element.id, name: element.name, image: element.image_urls ? element.image_urls[0] : '' }],
        })
      } else {
        this.compareProductsShow.find(p => p.product_type_id === element.product_type_id).products.push({ id: element.id, name: element.name, image: element.image_urls ? element.image_urls[0] : '' })
      }
    });
  }

  deleteCompareProduct(productId) {
    this.compareService.removeCompareProduct(productId);
  }

  compare(typeId) {
    this.showCompareBubble = false;
    this.router.navigate(['/product-compare'], { queryParams: { type: typeId } });
  }

  getCart() {
    this.cart = this.cartService.getCart();
  }

  scrollToTop() {
    if (isPlatformBrowser(this.platformId)) {
      const main = document.querySelector('.website-root main');
      main.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

}
