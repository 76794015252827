import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ns-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  @Input() footerNavigation;

  constructor(
    public router: Router,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit(): void {
  }

  navigate(navItem) {
    if(navItem.type === 'external_url') {
      if (isPlatformBrowser(this.platformId)) {
        window.open(navItem.data.external_url, '_blank');
      }
    } else {
      this.router.navigate([navItem.type, navItem.slug]);
    }
  }

}
