
 <nav class="ns-navigation">
  <div class="orders-info__wrapper">
    <div class="orders-info__container">
      <p class="small">Besplatno slanje za sve web porudžbine preko 5000 din, sem za TACX.</p>
      <div class="contact-info">
        <a href="tel: 011 241-2362"> <span class="ns-icon contact-phone"></span> 011 241-2362</a>
        <span class="separator">|</span>
        <a href="tel: 011 241-2342">011 241-2342</a>
        <a href="mailto: office@navishop.rs"> <span class="ns-icon contact-email"></span> office&#64;navishop.rs</a>
      </div>
    </div>
  </div>
  <div class="ns-navigation__wrapper">
    <div class="ns-navigation__container">
      <div class="ns-logo" (click)="navigate(homePage)"></div>
      <ul class="ns-navigation__items">
        <li
          class="main-navigation-item"
          *ngFor="let item of mainNavigation"
          [id]="item.title"
          (mouseenter)="item.subitems?.length > 0 ? toggleDropdown(item, true) : ''"
          (mouseleave)="item.subitems?.length > 0 ? toggleDropdown(item, false) : ''"
        >
          <span (click)="item.subitems?.length > 0 ? '' : navigate(item)">{{item.title}} <span *ngIf="item.subitems?.length > 0" class="it-icon arrow-down"></span> </span>
            <ul class="navigation-dropdown__wrapper" [id]="item.slug" *ngIf="item.subitems?.length > 0">
              <li class="navigation-dropdown">
                <ul class="navigation-dropdown__list" [class.fixed-width]="subitem?.subsubitems?.length > 7" *ngFor="let subitem of item?.subitems">
                  <li class="list-item__title" (click)="subitem?.subsubitems?.length > 0 ? '' : navigate(subitem)">{{subitem?.title}}</li>
                  <li>
                    <ul class="list-items" *ngIf="subitem?.subsubitems?.length > 0">
                    <li *ngFor="let subsubitem of subitem?.subsubitems" (click)="navigate(subsubitem)">{{subsubitem?.title}}</li>
                  </ul>
                  </li>

                </ul>
                <a class="promo-wrapper it-clickable" [routerLink]="['/product', item.featured_product.slug]" *ngIf="item.featured_product">
                  <img *ngIf="item.featured_product.image_urls"
                    itImageHandler
                    [apiRoot]="apiRoot"
                    [imageSize]="'medium'"
                    [imagePath]="item.featured_product.image_urls[0]"
                  >
                  <h2 class="promo-description">{{ item.featured_product.name }}</h2>
                  <p class="promo-description">{{ item.featured_product.description }}</p>            
                </a>
              </li>
            </ul>
        </li>
      </ul>
      <div class="navigation-actions" *ngIf="!showSearchInput">
        <span class="ns-icon cart" [routerLink]="['/profile/cart']"> <span class="notification" *ngIf="showNotification"></span> </span>
        <span class="ns-icon search" (click)="showSearch()"></span>
        <div itDropdown position="bottom" *ngIf="currentUser">
            <span class="ns-icon login"></span>
            <div itDropdownMenu class="it-dropdown__list">
              <ul>
                <li [routerLink]="['/profile']">Korisnički profil</li>
                <li (click)="logout()">Izloguj se</li>
              </ul>
            </div>
        </div>
        <div *ngIf="!currentUser">
            <span class="ns-icon login" [routerLink]="['/login']"></span>
        </div>
      </div>
      <it-input 
        itOffClick (offClick)="showSearch()" 
        *ngIf="showSearchInput" 
        type="search" placeholder="Pretraži shop..."
        [(ngModel)]="searchTerm"
        [ngModelOptions]="{ standalone: true }"
        (keyup.enter)="search()"
        (searchTerm)="search()"
      ></it-input>
    </div>
  </div>
 </nav>


 <nav class="ns-navigation__mobile">
  <div class="ns-logo" (click)="navigate(homePage);"></div>
  <div class="ns-nav__actions">
    <span class="ns-icon cart" [routerLink]="['/profile/cart']"> <span class="notification" *ngIf="showNotification"></span> </span>
    <ng-container *ngIf="currentUser">
      <span class="ns-icon login" [routerLink]="['/profile']"></span>
    </ng-container>
    <ng-container *ngIf="!currentUser">
      <span class="ns-icon login" [routerLink]="['/login']"></span>
    </ng-container>
    <div class="ns-icon" [class.menu]="!showNavigation" [class.menu-close]="showNavigation"  (click)="toggleNavigation()"></div>
  </div>

  <it-input  
    type="search" placeholder="Pretraži shop..."
    [(ngModel)]="searchTerm"
    [ngModelOptions]="{ standalone: true }"
    (keyup.enter)="search()"
    (searchTerm)="search()"
  ></it-input>
  <div class="navigation-wrapper" [class.show]="showNavigation">
    <ul *ngIf="!_type">
      <li class="nav-item" *ngFor="let item of mainNavigation" (click)="item?.subitems.length > 0 ? toggleSubnavigation($event, item?.id) : ''">
        <span class="title" [class.active]="router.url.includes(item.slug)" (click)="item?.subitems?.length > 0 ? '' : navigate(item); ">{{item.title}} <span class="ns-icon arrow-right"  *ngIf="item?.subitems.length > 0"></span></span>
        <ul class="nav-sub__items" *ngIf="item?.subitems.length > 0" [id]="item?.id">
          <li class="go-back" (click)="toggleSubnavigation($event, item?.id)"><span class="ns-icon arrow-left"></span>Nazad</li>
          <li class="caption" (click)="stopPropagination($event)">{{item.title}}</li>
          <li class="nav-item sub" *ngFor="let subitem of item.subitems" (click)="subitem?.subsubitems.length > 0 ? toggleSubnavigation($event, subitem.id) : ''">
            <span class="title"  (click)="subitem?.subsubitems?.length > 0 ? '' : navigate(subitem);">{{subitem.title}}<span class="it-icon" *ngIf="subitem?.subsubitems.length > 0" [class.arrow-down]="!selectedNavItems.includes(subitem?.id)" [class.arrow-up]="selectedNavItems.includes(subitem?.id)"></span></span>
            <ul class="nav-sub-sub__items" *ngIf="subitem?.subsubitems.length > 0" [id]="subitem?.id">
              <li *ngFor="let subsubitem of subitem.subsubitems" (click)="navigate(subsubitem);">{{subsubitem?.title}}</li>
            </ul>
          </li>
        </ul>
      </li>
      <li class="nav-item login" [routerLink]="['/login']">
        <span class="title">Ulogujte se</span>
      </li>
      <li class="nav-item">
        <span class="title contact bold">Kontaktirajte nas</span>
      </li>
      <li class="nav-item">
        <a class="title contact" href="tel: 011 241-2362">011 241-2362</a>
      </li>
      <li class="nav-item">
        <a class="title contact" href="tel: 011 241-2342">011 241-2342</a>
      </li>
      <li class="nav-item">
        <a class="title contact" href="mailto: office@navishop.rs">office&#64;navishop.rs</a>
      </li>
    </ul>
    <ul *ngIf="_type === 'categories'">
      <li class="nav-item categories" *ngFor="let item of additionalNavigation">
        <span class="title bold">{{item.title}}</span>
         <ul class="nav-sub__items">
          <li class="nav-item sub" *ngFor="let subitem of item.subitems" (click)="subitem?.subsubitems.length > 0 ? toggleSubnavigation($event, subitem.id) : ''">
            <span class="title">{{subitem.title}} <span class="it-icon arrow-down" *ngIf="subitem.subsubitems.length > 0"></span></span>
            <ul class="nav-sub-sub__items" *ngIf="subitem?.subsubitems.length > 0" [id]="subitem?.id">
              <li *ngFor="let subsubitem of subitem.subsubitems">{{subsubitem?.title}}</li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</nav>
