import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class WebsiteInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headers = {};
    headers['website_id'] = '8c63a0c5-6257-44dc-ae38-e2d757e64503';
    request = request.clone({
      setHeaders: headers
    });
    return next.handle(request)
      .pipe(tap(
        (event: HttpEvent<any>) => { },
        (error: any) => { }
      ));
  }
}
